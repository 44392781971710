<template>
  <v-container>
    <v-card class="elevation-2 mx-auto" :loading="loading" max-width="600">
      <v-card-title>Add Resource</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <form>
          <v-row>
            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-text-field
                v-model="form_data.title"
                :error-messages="form_error.title"
                dense
                label="Title"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-textarea
                rows="3"
                v-model="form_data.description"
                :error-messages="form_error.description"
                dense
                label="Description"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-file-input
                v-model="form_data.file"
                :error-messages="form_error.file"
                accept="image/*, video/*"
                placeholder="Select File"
                prepend-icon="mdi-file"
                outlined
                dense
              ></v-file-input>
            </v-col>
          </v-row>
          <v-btn
            class="mr-4"
            color="secondary"
            @click="addObject"
            :loading="loading"
            :disabled="loading"
          >submit</v-btn>
        </form>
      </v-card-text>
    </v-card>
    <v-btn small color="secondary" fixed fab bottom left :to="'/vendor/resource/'+form_data.vendor_id">
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
  </v-container>
</template>
<script>
import { mapMutations } from "vuex";
export default {
  name: "AddVendor",
  data() {
    return {
      positions: [],
      form_data: {
        vendor_id:null,
        resource_type_id:3,
      },
      form_error: {},
      loading: false,
      data: {},
    };
  },
  methods: {
    ...mapMutations("utils", ["setAlert"]),
    addObject() {
      let _self = this;
      this.loading = "secondary";
      let serverData = JSON.parse(JSON.stringify(this._data.form_data));
      let formData = new FormData();
      for (let key in serverData) {
        formData.append(key, serverData[key]);
      }
      if (this.form_data.file) {
        formData.append("file", this.form_data.file);
      }
      this.$axios.post(`admin/resource/add`, formData)
        .then((response) => {
          _self.form_error = {};
          let response_data = response.data;
          if (response_data.status) {
            _self.setAlert({
              show: true,
              variant: "success",
              message: response.data.message,
              dismissCountDown: 5000,
            });
            this.user_data = response_data.user_data;
            _self.$router.push("/vendor/resource/"+_self.form_data.vendor_id);
          } else {
            let errors = response_data.error;
            for (let key in errors) {
              _self.form_error[errors[key].field] = errors[key].message;
            }
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
  },
  mounted: function () {
    this.form_data.vendor_id = this.$route.params.vendor_id
  },
};
</script>